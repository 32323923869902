export const dojos = [
  {
    name: "Dojo hombu Tanabe",
    lon: -71.61577689815809,
    lat: 10.676713346727587,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Hanshi Angel Urribarri",
    address: "Calle 67A con Av 11. Sector tierra Negra. Al lado de Banzai Sushi",
    phone: "5804146160450",
  },
  {
    name: "Dojo San Francisco",
    lon: -71.63951813583556,
    lat: 10.574483705455178,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Sensei Roxana Urdaneta",
    address: "Av. 10 de San Felipe, diagonal al antiguo 'Pagué Menos'",
    phone: "04246165925 / 04246247264",
  },
  {
    name: "Dojo Nozomi",
    lon: -71.64881873973916,
    lat: 10.709305189335732,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Shihan Joel Millano",
    address: "urbanización la Esperanza parroquia idelfonso Vasquez preescolar la esperanza",
    phone: "584246753049",
  },
  {
    name: "Dojo Maki Gadema",
    lon: -72.54089805862397,
    lat: 10.060994408631938,
    country: "Venezuela",
    state: "Zulia",
    city: "Machiques de Perijá",
    instructor: "Sensei Jesús Augusto Romero",
    address: "Av. Arimpia. Club GADEMA",
    phone: "584126885910",
  },
  {
    name: "Dojo Bushiwaza",
    lon: -71.63300289038072,
    lat: 10.553322133718035,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Sensei Merly Urdaneta",
    address: "Av 16 entre calles 21 y 22, sector San Ramón, parroquia y municipio San Francisco, al lado del hospital Materno infantil de San Francisco",
    phone: "584246511614",
  },
  {
    name: "Dojo Zenkokan",
    lon: -71.63001110922126,
    lat: 10.58864461029343,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Sensei Lenning Medina",
    address: "Av. 19, calle 10-B Cancha de usos múltiples 'María Jung' Al lado del Colegio 19 de Abril. Sierra Maestra",
    phone: "584247452211",
  },
  {
    name: "Dojo TOK'O",
    lon: -71.30058656644147,
    lat: 10.1941174117979,
    country: "Venezuela",
    state: "Zulia",
    city: "Ciudad Ojeda",
    instructor: "Shihan Willian Izzarra",
    address: "Av. La 'N', edificio Bari, frente a la librería San Agustín, Ciudad Ojeda - Edo Zulia",
    phone: "5804246418225",
  },
  {
    name: "Dojo Impdeprec",
    lon: -71.61586745594508,
    lat: 10.64446819627546,
    country: "Venezuela",
    state: "Zulia",
    city: "Maracaibo",
    instructor: "Shihan-dai Pedro Valera",
    address: "Av Padilla, Torres del Saladillo detrás de la Torre Cumana / Av Padilla Plaza Urdaneta en el piso pulido que esta frente a la fuente de agua",
    phone: "584146399805",
  },
  {
    name: "Dojo Hinomaru",
    lon: -72.22273808785175,
    lat: 7.770404870888434,
    country: "Venezuela",
    state: "Tachira",
    city: "San Cristóbal",
    instructor: "Sensei Nelson Rosales",
    address: "Calle 15 con carrera 20 en el chalet",
    phone: "584147558583",
  },
  {
    name: "Dojo IAMDA",
    lon: -71.30058656644147,
    lat: 10.1941174117979,
    country: "Venezuela",
    state: "Zulia",
    city: "Ciudad Ojeda",
    instructor: "Sensei Néstor Montes",
    address: "Av. La 'N', edificio Bari, frente a la librería San Agustín, Ciudad Ojeda - Edo Zulia",
    phone: "04146757970",
  },
  {
    name: "Dojo GenBuKan",
    lon: -71.466456,
    lat: 10.385621,
    country: "Venezuela",
    state: "Zulia",
    city: "Cabimas",
    instructor: "Félix Blanco",
    address: "Delicias Nueva, calle Las Flores, al lado cancha techada, Cabimas, Estado Zulia",
    phone: null,
  }
];
